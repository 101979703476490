import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import style from '../../css/recommendation.module.css'

export default class Recommendation extends Component {
  render() {
    const { data } = this.props

    return (
      <div className={`${style.container} centerFlex spaceTopBottom`}>
        <table className={style.recommendation}>
          <caption>
            <h2 className="redText underline-red underline-center">Produse recomandate</h2>
          </caption>
          <tr>
            <td>
              <img src={data.image} alt="Iarba" />
              <h4>Fertilizant dfgjnfgj</h4>
              <Link to="/" className="button-red">
                VEzi produsul
              </Link>
            </td>
            <td>
              <img src={data.image} alt="Iarba" />
              <h4>Fertilizant dtjjhgjmuy</h4>
              <Link to="/" className="button-red">
                VEzi produsul
              </Link>
            </td>
            <td>
              <img src={data.image} alt="Iarba" />
              <h4>Fertilizant sfghgfj</h4>
              <Link to="/" className="button-red">
                VEzi produsul
              </Link>
            </td>
          </tr>
        </table>
      </div>
    )
  }
}

Recommendation.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.any,
  }).isRequired,
}
