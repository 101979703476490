import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from '../../css/singleLineTiles.module.css'

export default class SingleLineTilesText extends Component {
  render() {
    const { data } = this.props

    return (
      <div className={`${style.container} centerFlex`}>
        <div className={`${style.content} centerFlex maxContent`}>
          {data.items.map(item => (
            <div key={item.description.text} className={style.tile}>
              <h2>{item.title}</h2>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

SingleLineTilesText.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
      })
    ),
  }).isRequired,
}
