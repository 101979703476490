import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from '../../css/tools/button.module.css'

export default class Button extends Component {
  render() {
    const { data, className } = this.props
    const params = data.button_param !== null && data.button_param !== undefined ? data.button_param : ''

    return (
      <div className={className}>
        <div className={style.button}>
          <a
            key={data.button_text}
            href={
              // eslint-disable-next-line no-nested-ternary
              data.button_link != null
                ? `${data.button_link.url}${params}`
                : data.button_link_document != null
                ? data.button_link_document.url
                : null
            }
          >
            <h5 className={style.link}>{data.button_text}</h5>
          </a>
        </div>
      </div>
    )
  }
}

Button.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    button_text: PropTypes.string.isRequired,
    button_link: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    button_link_document: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    button_param: PropTypes.string,
  }).isRequired,
}

Button.defaultProps = {
  className: null,
}
