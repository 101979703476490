import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from '../../css/accordion.module.css'

const ReactDOM = require('react-dom')

export default class Panel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      height: 0,
    }
  }

  componentDidMount() {
    window.setTimeout(() => {
      const el = ReactDOM.findDOMNode(this)
      const height = el.querySelector('[class*="panel__inner"]').scrollHeight
      this.setState({
        height,
      })
    }, 333)
  }

  render() {
    const { title, description, activeTab, index, activateTab } = this.props
    const { height } = this.state
    const isActive = activeTab === index
    const innerStyle = {
      height: `${isActive ? height : 0}px`,
    }

    return (
      <div className={style.panel} role="tabpanel" aria-expanded={isActive}>
        <button className={style.panel__label} role="tab" onClick={activateTab} type="button">
          <h3>{title}</h3>
        </button>
        <div
          className={style.panel__inner}
          style={innerStyle}
          aria-hidden={!isActive}
          dangerouslySetInnerHTML={{
            __html: `${description.html} `,
          }}
        />
      </div>
    )
  }
}

// TODO: check PropTypes.any. Ramane asa?
Panel.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  activeTab: PropTypes.any.isRequired,
  index: PropTypes.any.isRequired,
  activateTab: PropTypes.any.isRequired,
}
