import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import style from '../../css/slideshowImageStatic.module.css'

export default class SlideshowImageStatic extends Component {
  render() {
    const { data, deviceType } = this.props
    return (
      <div className={style.container}>
        <Img
          className={style.background}
          fluid={data.primary.image.localFile.childImageSharp.fluid}
          alt={data.primary.image.alt}
        />
        <Carousel
          className={style.carousel}
          swipeable={false}
          draggable={false}
          showDots
          responsive={{
            desktop: {
              breakpoint: { max: 6000, min: 1024 },
              items: 1,
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 1,
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
            },
          }}
          ssr // means to render carousel on server-side.
          infinite
          autoPlay={deviceType !== 'mobile'}
          autoPlaySpeed={6000}
          keyBoardControl={false}
          removeArrowOnDeviceType={['tablet', 'mobile']}
          customTransition="transform 1000ms ease-in-out"
          transitionDuration={1000}
          deviceType={deviceType}
          dotListClass={style.dotlist}
          itemClass="centerFlex"
        >
          {data.items.map(item => (
            <div className={style.content} key={item.author}>
              <h4>{item.title}</h4>
              <p>{item.description}</p>
              <br />
              <span>{item.author}</span>
            </div>
          ))}
        </Carousel>
      </div>
    )
  }
}

SlideshowImageStatic.propTypes = {
  data: PropTypes.shape({
    primary: PropTypes.shape({
      image: PropTypes.shape({
        localFile: PropTypes.shape({
          url: PropTypes.string.isRequired,
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
              originalName: PropTypes.string.isRequired,
            }),
          }),
        }),
        alt: PropTypes.any.isRequired,
      }),
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        author: PropTypes.string.isRequired,
      }).isRequired
    ),
  }).isRequired,
  deviceType: PropTypes.object,
}

SlideshowImageStatic.defaultProps = {
  deviceType: `desktop`,
}
