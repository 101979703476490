import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

export default class Image extends Component {
  render() {
    const { data } = this.props

    return (
      <img className="background contentText" src={data.primary.image.localFile.url} alt={data.primary.image.alt} />
    )
  }
}

Image.propTypes = {
  data: PropTypes.shape({
    primary: PropTypes.shape({
      image: PropTypes.shape({
        localFile: PropTypes.shape({
          url: PropTypes.string.isRequired,
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.any.isRequired,
          }),
        }),
        alt: PropTypes.any.isRequired,
      }),
    }).isRequired,
  }).isRequired,
}
