import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImageText from './imageText'
import style from '../../css/imageText.module.css'

export default class RepeatableImageText extends Component {
  render() {
    const { data } = this.props
    return (
      <div className={`${style.link} ${style.container} maxContent centerFlex`}>
        {data.items.map(item => (
          <div className={`${style.content} centerFlex`}>
            <img className={`${style.imgstyle}`} src={item.image.localFile.url} alt={item.image.alt} />
            <div
              dangerouslySetInnerHTML={{
                __html: `${item.text.html} `,
              }}
            />
          </div>
        ))}
      </div>
    )
  }
}

RepeatableImageText.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf({
      data: PropTypes.shape({
        text: PropTypes.shape({
          html: PropTypes.string.isRequired,
        }),
        image: PropTypes.shape({
          localFile: PropTypes.shape({
            url: PropTypes.string.isRequired,
            childImageSharp: PropTypes.shape({
              fluid: PropTypes.any.isRequired,
            }),
          }),
          alt: PropTypes.any.isRequired,
        }),
      }).isRequired,
    }),
  }).isRequired,
}
