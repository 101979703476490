import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import style from '../../css/gallery.module.css'

export default class Gallery extends Component {
  render() {
    const { data } = this.props

    return (
      <div className={style.container}>
        {data.items.map(item => (
          <div className={style.content}>
            <img className={style.image} src={item.image.localFile.url} alt={item.image.alt} />
          </div>
        ))}
      </div>
    )
  }
}

Gallery.propTypes = {
  data: PropTypes.shape({
    primary: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }),
    items: PropTypes.arrayOf({
      image: PropTypes.shape({
        localFile: PropTypes.shape({
          extension: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.any.isRequired,
          }),
        }),
        alt: PropTypes.any.isRequired,
      }),
    }),
  }).isRequired,
}
