import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import styled from 'styled-components'
import style from '../../css/youtube.module.css'

// const ICustom = styled.i`
//   opacity: ${props => (props.slide === props.currentSlide ? 0.9 : 0.5)};
// `

export default class Youtube extends Component {
  render() {
    const { data } = this.props
    return (
      <div className={style.mainContainer}>
        <div className={style.container}>
          <iframe
            className={style.frame}
            title={`${data.primary.title_youtube}`}
            src={`${data.primary.link.url}`}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          />
        </div>
      </div>
    )
  }
}

Youtube.propTypes = {
  data: PropTypes.shape({
    primary: PropTypes.shape({
      title_youtube: PropTypes.string.isRequired,
      link: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
}
