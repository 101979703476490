import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class TitleWithTextCentered extends Component {
  render() {
    const { data } = this.props

    return (
      <div className="contentText">
        <h2 className="redText underline-red underline-center">{data.primary.title.text}</h2>
        <p>{data.primary.text.text}</p>
      </div>
    )
  }
}

TitleWithTextCentered.propTypes = {
  data: PropTypes.shape({
    primary: PropTypes.shape({
      title: PropTypes.shape({
        text: PropTypes.string.isRequired,
      }),
      text: PropTypes.shape({
        text: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
}
