import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query'
import PropTypes from 'prop-types'
import Layout from './layout'
import SliceZone from './sliceZone'
import SEO from './seo'

const urlPropsQueryConfig = {
  param: { type: UrlQueryParamTypes.number, queryParam: 'productVariationType' },
}

class Page extends Component {
  render() {
    const { data, path, param } = this.props
    return (
      <Layout>
        <SEO data={{ path, title: data.prismicPage.data.title, description: data.prismicPage.data.description }} />
        <SliceZone allSlices={data.prismicPage.data.body} params={param} />
      </Layout>
    )
  }
}

// const Page = ({ data: { prismicPage } }) => {
//   const { data } = prismicPage
//   const params = test.useQueryParam('productVariationType', 0)
//   return (
//     <Layout>
//       <SEO />
//       <SliceZone allSlices={data.body} params={params} />
//     </Layout>
//   )
// }

Page.propTypes = {
  param: PropTypes.number,
  path: PropTypes.string.isRequired,
  data: PropTypes.shape({
    prismicPage: PropTypes.shape({
      id: PropTypes.string.isRequired,
      data: PropTypes.shape({
        tag: PropTypes.string.isRequired,
        title: PropTypes.string,
        description: PropTypes.string,
        body: PropTypes.array.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

Page.defaultProps = {
  param: 0,
}

export default addUrlProps({ urlPropsQueryConfig })(Page)

export const pageQuery2 = graphql`
  query getPage($id: String) {
    prismicPage(id: { eq: $id }) {
      id
      data {
        tag
        title
        description
        body {
          ... on PrismicPageBodyPageHeader {
            id
            slice_type
            dataHeader: primary {
              title
              description
              button_text
              button_link {
                url
              }
              published
              header_type
              background {
                copyright
                localFile {
                  url
                  name
                  childImageSharp {
                    fluid(maxWidth: 3440) {
                      tracedSVG
                      aspectRatio
                      src
                      sizes
                      originalName
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyAccordion {
            id
            slice_type
            accordionItems: items {
              title
              description {
                html
                text
              }
            }
          }
          ... on PrismicPageBodyContact {
            id
            slice_type
            primary {
              text {
                html
                text
              }
              link {
                url
              }
            }
          }
          ... on PrismicPageBodyRepeatableImageText {
            id
            slice_type
            items {
              image {
                copyright
                alt
                localFile {
                  url
                  name
                }
              }
              text {
                html
              }
            }
          }
          ... on PrismicPageBodyCallToActionMultiButton {
            id
            slice_type
            dataMultiButtons: primary {
              title
              description
              background {
                alt
                copyright
                localFile {
                  url
                  name
                  childImageSharp {
                    fluid(maxWidth: 3440) {
                      tracedSVG
                      aspectRatio
                      src
                      sizes
                      originalName
                    }
                  }
                }
              }
            }
            buttons: items {
              button_text
              button_link {
                url
              }
            }
          }
          ... on PrismicPageBodyCallToActionSubscribe {
            id
            slice_type
            dataSubscribe: primary {
              title
              description
              button_text
              button_placeholder
              gdpragreementtext {
                text
              }
              background {
                alt
                copyright
                localFile {
                  url
                  name
                  childImageSharp {
                    fluid(maxWidth: 3440) {
                      tracedSVG
                      aspectRatio
                      src
                      sizes
                      originalName
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyImageMultiButton {
            id
            slice_type
            primary {
              background {
                alt
                copyright
                localFile {
                  url
                  name
                }
              }
            }
            items {
              button_text
              button_link {
                url
              }
            }
          }
          ... on PrismicPageBodyOffer {
            id
            slice_type
            primary {
              title {
                text
              }
              background {
                alt
                copyright
                localFile {
                  url
                  name
                  childImageSharp {
                    fluid(maxWidth: 3440) {
                      tracedSVG
                      aspectRatio
                      src
                      sizes
                      originalName
                    }
                  }
                }
              }
            }
            items {
              text {
                text
                html
              }
              image {
                copyright
                localFile {
                  extension
                  url
                  name
                }
              }
            }
          }
          ... on PrismicPageBodySlideshow {
            id
            slice_type
            primary {
              type
              button_text
              button_link {
                url
              }
            }
            items {
              title
              description
              image {
                alt
                copyright
                localFile {
                  url
                  name
                }
              }
            }
          }
          ... on PrismicPageBodySlideshowmare {
            id
            slice_type
            items {
              title: title1
              description: description1
              button_text
              button_link {
                url
              }
              image {
                alt
                copyright
                localFile {
                  url
                  name
                  childImageSharp {
                    fluid(maxWidth: 3440) {
                      tracedSVG
                      aspectRatio
                      src
                      sizes
                      originalName
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodySlideshowImageStatic {
            id
            slice_type
            primary {
              image {
                alt
                copyright
                localFile {
                  url
                  name
                  childImageSharp {
                    fluid(maxWidth: 3440) {
                      tracedSVG
                      aspectRatio
                      src
                      sizes
                      originalName
                    }
                  }
                }
              }
            }
            items {
              title
              description
              author
            }
          }
          ... on PrismicPageBodySlideshowTextStatic {
            id
            slice_type
            dataSlideshow: primary {
              title
              description
            }
            items {
              image {
                alt
                copyright
                localFile {
                  extension
                  url
                  name
                  childImageSharp {
                    fluid(maxWidth: 3440) {
                      tracedSVG
                      aspectRatio
                      src
                      sizes
                      originalName
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyTableCharacteristics {
            id
            slice_type
            primary {
              title {
                text
              }
            }
            items {
              characteristic {
                text
              }
              value {
                html
              }
            }
          }
          ... on PrismicPageBodyTimeline {
            id
            slice_type
            items {
              title
              description
            }
          }
          ... on PrismicPageBodyTiles {
            id
            slice_type
            primary {
              type
            }
            items {
              title
              description
              number
              button_text
              button_link {
                url
              }
              button_link_document {
                url
              }
              button_param
              image {
                copyright
                alt
                localFile {
                  extension
                  url
                  name
                  childImageSharp {
                    fluid(maxWidth: 750) {
                      tracedSVG
                      aspectRatio
                      src
                      sizes
                      originalName
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyMultiTabImageText {
            id
            slice_type
            items {
              title
              text {
                html
                text
              }
              image {
                copyright
                alt
                localFile {
                  extension
                  url
                  name
                }
              }
            }
          }
          ... on PrismicPageBodyListGroup {
            id
            slice_type
            primary {
              icon {
                localFile {
                  extension
                  url
                  name
                }
              }
            }
            items {
              text {
                text
              }
            }
          }
          ... on PrismicPageBodyText {
            id
            slice_type
            primary {
              type
              anchorid
              text {
                text
                html
              }
            }
          }
          ... on PrismicPageBodySingleLineTilesText {
            id
            slice_type
            items {
              title
              description
            }
          }
          ... on PrismicPageBodySingleLineTilesIconsText {
            id
            slice_type
            items {
              image {
                alt
                copyright
                localFile {
                  extension
                  url
                  name
                }
              }
            }
          }
          ... on PrismicPageBodyLogoParteneri {
            id
            slice_type
            items {
              image {
                alt
                copyright
                localFile {
                  extension
                  url
                  name
                }
              }
            }
          }
          ... on PrismicPageBodySingleLineTilesImageText {
            id
            slice_type
            items {
              title
              link {
                url
              }
              image {
                alt
                copyright
                localFile {
                  url
                  name
                }
              }
            }
          }
          ... on PrismicPageBodyImage {
            id
            slice_type
            primary {
              image {
                copyright
                localFile {
                  url
                  name
                }
              }
            }
          }
          ... on PrismicPageBodyGallery {
            id
            slice_type
            items {
              image {
                copyright
                alt
                localFile {
                  extension
                  url
                  name
                }
              }
            }
          }
          ... on PrismicPageBodyProduct {
            id
            slice_type
            primary {
              product {
                document {
                  id
                  product: data {
                    name
                    description {
                      text
                    }
                    variations: body1 {
                      slice_type
                      primary {
                        type
                      }
                      items {
                        images {
                          copyright
                          localFile {
                            url
                            name
                            childImageSharp {
                              fluid(maxWidth: 1000) {
                                tracedSVG
                                aspectRatio
                                src
                                sizes
                                originalName
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyEmbed {
            id
            slice_type
            primary {
              title_header
              description_header
              video {
                url
                link_type
                name
                kind
                size
              }
            }
          }
          ... on PrismicPageBodyYoutube {
            id
            slice_type
            primary {
              title_youtube
              link {
                url
              }
            }
          }
        }
      }
    }
  }
`
