import React, { Component } from 'react'

import styled from 'styled-components'
import styles from '../css/navMenu.module.css'

const createSubmenu = (menuData, categoryName) => {
  const submenu = menuData.map((subcategory, index) => {
    if (subcategory.primary.navigation_menu_item_name === categoryName) {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index}>
          {subcategory.primary.link ? (
            <a href={subcategory.primary.link.url}>
              <div className={styles.linkCategory}>
                <p className={`${styles.categoryTitle} centerFlex`}>{subcategory.primary.navigation_group_name}</p>
              </div>
            </a>
          ) : (
            <p className={`${styles.categoryTitle} centerFlex`}>{subcategory.primary.navigation_group_name}</p>
          )}

          <hr />
          <ul className={`${styles.submenuList} centerFlex`}>
            {subcategory.items.map(item => (
              <li key={item.menu_item_name}>
                {item.link ? (
                  <a href={item.link.url}>
                    <span className={styles.subcategory}>{item.menu_item_name}</span>
                  </a>
                ) : (
                  <span className={styles.subcategory}>{item.menu_item_name}</span>
                )}
              </li>
            ))}
          </ul>
        </li>
      )
    }
    return ''
  })

  return <ul className={styles.submenuContainer}>{submenu}</ul>
}

const createNavmenu = menuData => {
  const menuCategories = menuData.navigation_menu
  const menu = menuCategories.map((item, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <li key={index}>
      {item.link ? (
        <a href={item.link.url}>
          <h4 className={`${styles.menuTitle} centerFlex`}>{item.menu_item_name}</h4>
        </a>
      ) : (
        <h4 className={`${styles.menuTitle} centerFlex`}>{item.menu_item_name}</h4>
      )}
      {createSubmenu(menuData.body, item.menu_item_name)}
    </li>
  ))

  return (
    <div className={styles.navMenu}>
      <ul className={styles.menu}>{menu}</ul>
    </div>
  )
}

class NavMenu extends Component {
  render() {
    // eslint-disable-next-line react/prop-types
    const { data } = this.props

    return createNavmenu(data)
  }
}

export default NavMenu
