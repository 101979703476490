import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Carousel from 'nuka-carousel'
import ReactResizeDetector from 'react-resize-detector'
import Button from '../tools/button'
import 'react-multi-carousel/lib/styles.css'
import style from '../../css/slideshow.module.css'

export default class Slideshow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      slidesToShow: 4,
      cellSpacing: 10,
      withoutControls: false,
    }
  }

  onResize = width => {
    if (width > 1024) {
      this.setState(() => ({ slidesToShow: 4 }))
      this.setState(() => ({ cellSpacing: 10 }))
    } else if (width > 768) {
      this.setState(() => ({ slidesToShow: 3 }))
      this.setState(() => ({ cellSpacing: 7 }))
    } else {
      this.setState(() => ({ slidesToShow: width > 500 ? 2 : 1 }))
      this.setState(() => ({ cellSpacing: 5 }))
    }

    this.setState(() => ({ withoutControls: width < 1024 }))
  }

  render() {
    const { data } = this.props
    const { slidesToShow, cellSpacing, withoutControls } = this.state

    return (
      <div className={style.container}>
        <ReactResizeDetector handleWidth onResize={this.onResize} />
        <Carousel
          className={style.carousel}
          cellAlign="right"
          cellSpacing={cellSpacing}
          slidesToShow={slidesToShow}
          withoutControls={withoutControls}
          wrapAround
          autoplay
          zoomScale={2}
          slideWidth={1}
          autoplayInterval={4000}
          pauseOnHover
          heightMode="max"
          renderCenterLeftControls={({ previousSlide }) => (
            <button className={`${style.button} ${style.left}`} onClick={previousSlide} type="button">
              <i className={`fa fa-5x fa-arrow-circle-left ${style.arrows}`} />
            </button>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <button className={`${style.button} ${style.right}`} onClick={nextSlide} type="button">
              <i className={`fa fa-5x fa-arrow-circle-right ${style.arrows}`} />
            </button>
          )}
        >
          {data.items.map(item => (
            <img
              className={style.smallSlideshowImg}
              key={item.image.name}
              src={item.image.localFile.url}
              alt={item.image.alt}
            />
          ))}
        </Carousel>
        {data.primary.button_link !== null ? (
          <div className={style.slideshowBtn}>
            <Button data={data.primary} />
          </div>
        ) : null}
      </div>
    )
  }
}

Slideshow.propTypes = {
  data: PropTypes.shape({
    primary: PropTypes.shape({
      type: PropTypes.string.isRequired,
      button_text: PropTypes.string.isRequired,
      button_link: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        button_text: PropTypes.string,
        button_link: PropTypes.string,
        image: PropTypes.shape({
          localFile: PropTypes.shape({
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            childImageSharp: PropTypes.shape({
              fluid: PropTypes.shape({
                originalName: PropTypes.string.isRequired,
              }),
            }),
          }),
          alt: PropTypes.any.isRequired,
        }),
      }).isRequired
    ),
  }).isRequired,
}
