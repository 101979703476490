import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Button from '../tools/button'
import style from '../../css/imageMultiButton.module.css'

export default class ImageMultiButton extends Component {
  render() {
    const { data } = this.props

    return (
      <div className={style.container}>
        <img
          className={style.background}
          src={data.primary.background.localFile.url}
          alt={data.primary.background.alt}
        />
        <div className={style.buttons}>
          {data.items.map(item => (
            <Button data={item} />
          ))}
        </div>
      </div>
    )
  }
}

ImageMultiButton.propTypes = {
  data: PropTypes.shape({
    primary: PropTypes.shape({
      background: PropTypes.shape({
        localFile: PropTypes.shape({
          url: PropTypes.string.isRequired,
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.any.isRequired,
          }),
        }),
        alt: PropTypes.any.isRequired,
      }),
    }).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        button_text: PropTypes.string.isRequired,
        button_link: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      })
    ).isRequired,
  }).isRequired,
}
