import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import style from '../../css/singleLineTiles.module.css'

export default class SingleLineTilesIconsText extends Component {
  render() {
    const { data } = this.props

    return (
      <div className={`${style.container} centerFlex`}>
        <div className={`${style.content} centerFlex maxContent`}>
          {data.items.map(item =>
            item.image.localFile.extension !== 'svg' ? (
              <img className="background" src={item.image.localFile.url} alt={item.image.alt} />
            ) : (
              <img className={`${style.icons}`} src={item.image.localFile.url} alt={item.image.alt} />
            )
          )}
        </div>
      </div>
    )
  }
}

SingleLineTilesIconsText.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          localFile: PropTypes.shape({
            extension: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
          }),
          alt: PropTypes.any.isRequired,
        }),
      })
    ).isRequired,
  }).isRequired,
}
