import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from '../../css/accordion.module.css'
import Panel from './panel'

export default class Accordion extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: -1,
    }
    this.activateTab = this.activateTab.bind(this)
  }

  activateTab(index) {
    this.setState(prev => ({
      activeTab: prev.activeTab === index ? -1 : index,
    }))
  }

  render() {
    const { data } = this.props
    const { activeTab } = this.state
    return (
      <div className={`${style.accordion} maxContent spaceTopBottom`} role="tablist">
        {data.accordionItems.map((panel, index) => (
          <Panel
            key={index}
            activeTab={activeTab}
            index={index}
            {...panel}
            activateTab={this.activateTab.bind(null, index)}
          />
        ))}
      </div>
    )
  }
}

Accordion.propTypes = {
  data: PropTypes.shape({
    accordionItems: PropTypes.array.isRequired,
  }).isRequired,
}
