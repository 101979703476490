import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from '../../css/listGroup.module.css'

export default class ListGroup extends Component {
  render() {
    const { data } = this.props

    return (
      <div className={style.container}>
        {data.items.map(item => (
          <div className={style.content} key={item.text.text}>
            <img src={data.primary.icon.localFile.url} alt={data.primary.icon.alt} />
            <span>{item.text.text}</span>
          </div>
        ))}
      </div>
    )
  }
}

ListGroup.propTypes = {
  data: PropTypes.shape({
    primary: PropTypes.shape({
      icon: PropTypes.shape({
        localFile: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }),
        alt: PropTypes.any.isRequired,
      }),
    }).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }).isRequired,
      })
    ).isRequired,
  }).isRequired,
}
