import React, { Component } from 'react'
import MetisMenu from 'react-metismenu'
import PropTypes from 'prop-types'

import 'react-metismenu/dist/react-metismenu-standart.css'

export default class MobileMenu extends Component {
  render() {
    const { data } = this.props

    return <MetisMenu content={getData(data)} />
  }
}

MobileMenu.propTypes = {
  data: PropTypes.any.isRequired,
}

function getData(initialData) {
  const treeStructuredData = []

  initialData.navigation_menu.forEach(function(item) {
    const itemContent = getContent(item, initialData.body, initialData.mobile_menu_show_all_text)

    treeStructuredData.push({
      label: item.menu_item_name,
      content: itemContent,
    })
  })

  return treeStructuredData
}

function getContent(itemData, itemContent, showAllText) {
  const content = []

  if (itemData.mobile_menu_show_all.toLowerCase() === 'yes')
    content.push({
      label: showAllText,
      to: itemData.link.url,
    })

  itemContent.forEach(function(subItem) {
    if (subItem.primary.navigation_menu_item_name === itemData.menu_item_name) {
      const subItemContent = []

      if (subItem.primary.mobile_menu_show_all.toLowerCase() === 'yes') {
        const subItemLink = subItem.primary.link ? subItem.primary.link.url : ''
        subItemContent.push({
          label: showAllText,
          to: subItemLink,
        })
      }

      subItem.items.forEach(function(subSubItem) {
        subItemContent.push({
          label: subSubItem.menu_item_name,
          to: subSubItem.link ? subSubItem.link.url : '',
        })
      })
      content.push({
        label: subItem.primary.navigation_group_name,
        content: subItemContent,
        to: '',
      })
    }
  })

  return content
}
