import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import style from '../../css/offer.module.css'

export default class Offer extends Component {
  render() {
    const { data } = this.props

    return (
      <div className={style.container}>
        <Img
          className={style.background}
          fluid={data.primary.background.localFile.childImageSharp.fluid}
          alt={data.primary.background.alt}
        />
        <div className={style.overlay}>
          <h2>{data.primary.title.text}</h2>
          <div className={style.contentFlex}>
            {data.items.map((item, index) => (
              <div className={style.content}>
                <div
                  className={style.textContainer}
                  dangerouslySetInnerHTML={{
                    __html: `${item.text.html} `,
                  }}
                />
                <div className={style.imgContainer}>
                  <img className={style.image} src={item.image.localFile.url} alt={item.image.alt} />
                </div>
                <div className={style.empty} />
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

Offer.propTypes = {
  data: PropTypes.shape({
    primary: PropTypes.shape({
      title: PropTypes.shape({
        text: PropTypes.string.isRequired,
      }),
      background: PropTypes.shape({
        localFile: PropTypes.shape({
          url: PropTypes.string.isRequired,
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.any.isRequired,
          }),
        }),
        alt: PropTypes.any.isRequired,
      }),
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          localFile: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
          alt: PropTypes.any.isRequired,
        }),
        text: PropTypes.shape({
          text: PropTypes.string.isRequired,
          html: PropTypes.string.isRequired,
        }),
      })
    ).isRequired,
  }).isRequired,
}
