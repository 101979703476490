import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Button from '../tools/button'
import style from '../../css/categoryTile.module.css'

export default class CategoryTile extends Component {
  render() {
    const { data } = this.props

    return (
      <div className={style.container}>
        <div className={style.background}>
          <img className={style.image} src={data.image.localFile.url} alt={data.image.alt} />
        </div>
        <div className={style.content}>
          <h2 className="underline-white">{data.title}</h2>
          {data.button_link !== null ? <Button data={data} /> : null}
        </div>
      </div>
    )
  }
}

CategoryTile.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.shape({
      localFile: PropTypes.shape({
        extension: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        childImageSharp: PropTypes.shape({
          fluid: PropTypes.any.isRequired,
        }),
      }),
      alt: PropTypes.any.isRequired,
    }),
    button_text: PropTypes.string.isRequired,
    button_link: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
