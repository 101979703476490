import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import MessengerCustomerChat from 'react-messenger-customer-chat'
import Header from './header'
import Footer from './footer'
import '../css/layout.css'

// class Layout extends Component {
//   render() {
//     const { children } = this.props
//     return (
//       <div className="page">
//         {/* <Helmet>
//             <meta httpEquiv="Cache-control" content="no-cache, max-age=0, must-revalidate" />
//           </Helmet>
//           <Header siteTitle={data.site.siteMetadata.title} /> */}
//         <div className="content">{children}</div>
//         {/* <Footer /> */}
//       </div>
//     )
//   }
// }

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rendered: false,
    }
  }

  componentDidMount() {
    this.setState(() => ({ rendered: true }))
  }

  render() {
    const { children } = this.props
    const { rendered } = this.state

    return (
      <div className="page">
        <Header />
        <div className="content">{children}</div>
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="cookie-consent-form"
          style={{ background: '#2B373B', color: 'white' }}
          buttonStyle={{
            marginRight: '6rem',
            paddingTop: '0.75rem',
            paddingBottom: '0.75rem',
            borderRadius: '0.5rem',
            background: '#870b0b',
            color: 'white',
            fontSize: '16px',
            fontWeight: 'bold',
          }}
          expires={150}
          acceptOnScroll
          onAccept={({ acceptedByScrolling }) => {
            if (acceptedByScrolling) {
              // triggered if user scrolls past threshold
              // alert('Accept was triggered by user scrolling')
            } else {
              // alert('Accept was triggered by clicking the Accept button')
              Cookies.set('gatsby-gdpr-google-analytics', 'true')
              Cookies.set('gatsby-gdpr-facebook-pixel', 'true')
            }
          }}
          onDecline={() => {
            // alert('nay!')
            Cookies.remove('gatsby-gdpr-google-analytics')
            Cookies.remove('gatsby-gdpr-facebook-pixel')
          }}
        >
          <p>Acest website foloseste cookies. Cititi mai multe in politica de cookie-uri.</p>
        </CookieConsent>
        {rendered ? (
          <div>
            <MessengerCustomerChat
              pageId="406059686111218"
              appId="503403177192997"
              loggedInGreeting="Bună ziua! Cu ce vă putem ajuta?"
              language="ro_RO"
              debug={false}
              xfbml
              version="5.0"
            />
          </div>
        ) : null}
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.array.isRequired,
  data: PropTypes.shape({
    prismicPage: PropTypes.shape({
      id: PropTypes.string.isRequired,
      data: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.array.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export default Layout

// export const pageSEO = graphql`
//   query getPageSEO {
//     prismicPage(id: { eq: $id }) {
//       id
//       data {
//         title
//         description
//       }
//     }
//   }
// `
