import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

export default class SEO extends Component {
  render() {
    const { data, lang, meta } = this.props
    return (
      <Helmet
        htmlAttributes={{
          lang,
          meta,
        }}
        title={data.title}
        meta={[
          {
            name: `title`,
            content: data.title,
          },
          {
            name: `description`,
            content: data.description,
          },
          {
            property: `og:title`,
            content: data.title,
          },
          {
            property: `og:description`,
            content: data.description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
        ]}
      >
        <link rel="canonical" href={`https://gazonul.ro${data.path}`} />
      </Helmet>
    )
  }
}

SEO.defaultProps = {
  lang: `ro`,
  meta: [
    '<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />',
    '<meta http-equiv="Content-Type" content="text/html; charset=ISO-8859-1">',
    '<meta name=viewport content="width=device-width, initial-scale=1">',
  ],
}

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.array,
  data: PropTypes.shape({
    path: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.array.isRequired,
  }).isRequired,
}
