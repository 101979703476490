import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Carousel from 'nuka-carousel'
import ReactResizeDetector from 'react-resize-detector'
import Img from 'gatsby-image'
import Button from '../tools/button'
import 'react-multi-carousel/lib/styles.css'
import style from '../../css/slideshowMare.module.css'

export default class SlideshowMare extends Component {
  constructor(props) {
    super(props)
    this.state = {
      withoutControls: false,
    }
  }

  render() {
    const { data } = this.props
    const { withoutControls } = this.state

    return (
      <div className={style.container}>
        <ReactResizeDetector handleWidth onResize={width => this.setState(() => ({ withoutControls: width < 1024 }))} />
        <Carousel
          className={style.carousel}
          cellAlign="right"
          slidesToShow={1}
          withoutControls={withoutControls}
          wrapAround
          autoplay
          slideWidth={1}
          autoplayInterval={4000}
          pauseOnHover
          heightMode="first"
          renderCenterLeftControls={({ previousSlide }) => (
            <button className={`${style.button} ${style.left}`} onClick={previousSlide} type="button">
              <i className={`fa fa-5x fa-arrow-circle-left ${style.arrows}`} />
            </button>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <button className={`${style.button} ${style.right}`} onClick={nextSlide} type="button">
              <i className={`fa fa-5x fa-arrow-circle-right ${style.arrows}`} />
            </button>
          )}
        >
          {data.items.map(item => (
            <div className={style.content}>
              <Img
                className={style.smallSlideshowImg}
                key={item.image.name}
                fluid={item.image.localFile.childImageSharp.fluid}
                alt={item.image.alt}
              />
              <div className={style.overlay}>
                <h2>{item.title}</h2>
                <p>{item.description}</p>
                <Button data={item} />
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    )
  }
}

SlideshowMare.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        button_text: PropTypes.string,
        button_link: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }),
        image: PropTypes.shape({
          localFile: PropTypes.shape({
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            childImageSharp: PropTypes.shape({
              fluid: PropTypes.shape({
                originalName: PropTypes.string.isRequired,
              }),
            }),
          }),
          alt: PropTypes.any.isRequired,
        }),
      }).isRequired
    ),
  }).isRequired,
}
