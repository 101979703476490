import React, { Component } from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import Button from '../tools/button'
import style from '../../css/callToAction.module.css'

export default class CallToActionMultiButton extends Component {
  render() {
    const { data } = this.props

    return (
      <div className={style.container}>
        <Img
          className="background"
          fluid={data.dataMultiButtons.background.localFile.childImageSharp.fluid}
          alt={data.dataMultiButtons.background.alt}
        />
        <div className={style.content}>
          <h1>{data.dataMultiButtons.title}</h1>
          <p>{data.dataMultiButtons.description}</p>
          <div className={style.buttons}>
            {data.buttons.map(item => (
              <Button className={style.button} data={item} key={item.button_text} />
            ))}
          </div>
        </div>
      </div>
    )
  }
}

CallToActionMultiButton.propTypes = {
  data: PropTypes.shape({
    dataMultiButtons: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      background: PropTypes.shape({
        localFile: PropTypes.shape({
          url: PropTypes.string.isRequired,
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.any.isRequired,
          }).isRequired,
        }),
        alt: PropTypes.any.isRequired,
      }).isRequired,
    }).isRequired,
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        button_text: PropTypes.string.isRequired,
        button_link: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      })
    ),
  }).isRequired,
}
