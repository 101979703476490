import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from '../../css/logoParteneri.module.css'

export default class LogoParteneri extends Component {
  render() {
    const { data } = this.props

    return (
      <div className={style.container}>
        <div className={style.content}>
          {data.items.map(item => (
            <img src={item.image.localFile.url} alt={item.image.alt} />
          ))}
        </div>
      </div>
    )
  }
}

LogoParteneri.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          localFile: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
          alt: PropTypes.any.isRequired,
        }),
      })
    ).isRequired,
  }).isRequired,
}
