import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CategoryTile from './categoryTile'
import ArticleTile from './articleTile'
import IconTile from './iconTile'
import ProductTile from './productTile'
import JobTile from './jobTile'

import style from '../../css/tiles.module.css'

export default class Tiles extends Component {
  render() {
    const { data } = this.props

    switch (data.primary.type) {
      // These are the API IDs of the slices
      case 'CategoryTile':
        return (
          <div className={style.container}>
            {data.items.map(item => (
              <CategoryTile data={item} key={item.title} />
            ))}
          </div>
        )
      case 'ArticleTile':
        return (
          <div className={`${style.container} ${style.articleTiles}`}>
            {data.items.map(item => (
              <ArticleTile data={item} key={item.title} />
            ))}
          </div>
        )
      case 'VectorTile':
        return (
          <div className={style.container}>
            {data.items.map(item => (
              <IconTile data={item} key={item.title} />
            ))}
          </div>
        )
      case 'ProductTile':
        return (
          <div className="minimenu centerFlex maxContent spaceTopBottom">
            {data.items.map(item => (
              <ProductTile data={item} key={item.title} />
            ))}
          </div>
        )
      case 'JobTile':
        return (
          <div className={style.container}>
            {data.items.map(item => (
              <JobTile data={item} key={item.title} />
            ))}
          </div>
        )
      case 'ButtonTile':
        return createJobTiles(data.items)
      case 'NumberTile':
        return createCollaboratorsTiles(data.items)
      default:
        return null
    }
  }
}

Tiles.propTypes = {
  data: PropTypes.shape({
    primary: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }),
    items: PropTypes.array.isRequired,
  }).isRequired,
}

function createJobTiles(items) {
  const content = items.map(item => (
    <div className="centerFlex" key={item.title}>
      <div className="logoDescription">
        <h4>{item.title}</h4>
        <p>{item.description}</p>
      </div>
      <a href={item.button_link.url} className="button-red">
        {item.button_text}
      </a>
    </div>
  ))

  return <div className="icons maxContent">{content}</div>
}

function createCollaboratorsTiles(items) {
  const content = items.map(item => (
    <div className="centerFlex" key={item.title}>
      <div className="logoDescription collaboratorsTile">
        <h3>{item.title}</h3>
        <div
          dangerouslySetInnerHTML={{
            __html: `${item.description} `,
          }}
        />
        <h2>{item.number}</h2>
      </div>
    </div>
  ))

  return <div className="icons maxContent">{content}</div>
}
