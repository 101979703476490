import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Button from '../tools/button'
import style from '../../css/headerHomepage.module.css'

export default class PageHeader extends Component {
  render() {
    const { data } = this.props
    let containerStyle = ''
    switch (data.dataHeader.header_type.toUpperCase()) {
      case 'HOMEPAGE':
        containerStyle = style.homepage
        break
      case 'ARTICLE':
        containerStyle = style.article
        break
      case 'PRODUCT':
        containerStyle = style.product
        break
      default:
        break
    }

    return (
      <div className={`${style.container} ${containerStyle} centerFlex`}>
        <Img
          className={style.backgroundImg}
          fluid={data.dataHeader.background.localFile.childImageSharp.fluid}
          alt={data.dataHeader.background.alt}
        />
        <div
          className={`${style.content} ${
            data.dataHeader.header_type.toUpperCase() === 'HOMEPAGE' ? style.start : style.center
          }`}
        >
          <div>
            <h1>{data.dataHeader.title}</h1>
            <p>{data.dataHeader.description}</p>
          </div>
          {data.dataHeader.header_type.toUpperCase() === 'ARTICLE' ? (
            <p className={style.articleDetails}>{data.dataHeader.published}</p>
          ) : null}
          {data.dataHeader.header_type.toUpperCase() === 'HOMEPAGE' ? <Button data={data.dataHeader} /> : ''}
        </div>
      </div>
    )
  }
}

PageHeader.propTypes = {
  data: PropTypes.shape({
    dataHeader: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      header_type: PropTypes.string.isRequired,
      button_text: PropTypes.string.isRequired,
      button_link: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
      published: PropTypes.string.isRequired,
      background: PropTypes.shape({
        localFile: PropTypes.shape({
          url: PropTypes.string.isRequired,
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.any.isRequired,
          }),
        }),
        alt: PropTypes.any.isRequired,
      }),
    }).isRequired,
  }).isRequired,
}
