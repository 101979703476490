import React, { Component } from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import style from '../../css/imageText.module.css'

export default class ImageText extends Component {
  render() {
    const { data } = this.props

    return (
      <div className={`${style.content} centerFlex`}>
        <div className={style.backgroundImg}>
          <img src={data.image.localFile.url} alt={data.image.alt} />
        </div>
        <div
          className={style.contentText}
          dangerouslySetInnerHTML={{
            __html: `${data.text.html} `,
          }}
        />
      </div>
    )
  }
}

ImageText.propTypes = {
  data: PropTypes.shape({
    text: PropTypes.shape({
      html: PropTypes.string.isRequired,
    }),
    image: PropTypes.shape({
      localFile: PropTypes.shape({
        url: PropTypes.string.isRequired,
        childImageSharp: PropTypes.shape({
          fluid: PropTypes.any.isRequired,
        }),
      }),
      alt: PropTypes.any.isRequired,
    }),
  }).isRequired,
}
