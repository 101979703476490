import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import style from '../css/footer.module.css'

const createSubmenu = categoryItems => {
  const submenu = categoryItems.map(item => (
    <Link to={item.link.url} key={item.link_name}>
      <li className={style.underlink}>{item.link_name}</li>
    </Link>
  ))

  return <ul>{submenu}</ul>
}

const createFooterMenu = menuCategories => {
  const menu = menuCategories.map(category => (
    <div key={category.primary.group_name}>
      <Link to={category.primary.group.url}>
        <div className={style.header}>
          <h4 className={style.link}>{category.primary.group_name}</h4>
        </div>
      </Link>
      {createSubmenu(category.items)}
    </div>
  ))

  return <div className={style.menu}>{menu}</div>
}

const Footer = ({ data }) => (
  <footer>
    <div className={`${style.footer}`}>
      <div>
        <img className={`${style.logoSlogan}`} src={data.logo.url} alt={data.logo.name} />
      </div>
      {createFooterMenu(data.body)}
      <div className={`${style.info}`}>
        <h4>{data.contact_info_title}</h4>
        <a className={style.underlink} href={`mailto:${data.email.text}`}>
          {data.email.text}
        </a>
        <p>
          {data.address.text}
          <br />
          <a className={style.underlink} href={`tel:${data.phone_number.text}`}>
            {data.phone_number.text}
          </a>
        </p>
        <div className={`${style.icons}`}>
          {data.social_media.map(icon => (
            <a href={icon.link.url} target="_blank" rel="noopener noreferrer">
              <i className={`fa fa-2x ${icon.icon}`} key={icon.icon} />
            </a>
          ))}
        </div>
      </div>
    </div>
    <hr />
    <div className={`${style.safety}`}>
      <a className={style.underlink} href="/">
        {data.copyright.text}
      </a>
      <a className={style.underlink} href={data.terms_and_conditions_page.url}>
        {data.terms_and_conditions_name}
      </a>
      <a className={style.underlink} href={data.cookie_policy_page.url}>
        {data.cookie_policy_name}
      </a>
      <a className={style.underlink} href={data.gdpr_page.url}>
        {data.gdpr_name}
      </a>
      <a className={style.underlink} href={data.anpc_page.url}>
        {data.anpc_name}
      </a>
    </div>
  </footer>
)

Footer.propTypes = {
  data: PropTypes.shape({
    body: PropTypes.array,
    contact_info_title: PropTypes.string.isRequired,
    social_media: PropTypes.array,
    logo: PropTypes.shape({
      url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    phone_number: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
    address: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
    email: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
    copyright: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
    terms_and_conditions_page: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
    terms_and_conditions_name: PropTypes.string.isRequired,
    cookie_policy_page: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
    cookie_policy_name: PropTypes.string.isRequired,
    gdpr_page: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
    gdpr_name: PropTypes.string.isRequired,
    anpc_page: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
    anpc_name: PropTypes.string.isRequired,
  }).isRequired,
}

// eslint-disable-next-line react/display-name
export default props => (
  <StaticQuery
    query={graphql`
      query footerQuery {
        prismicFooter {
          data {
            name
            logo {
              name
              url
              link_type
            }
            contact_info_title
            phone_number {
              text
            }
            address {
              text
            }
            email {
              text
            }
            social_media {
              icon
              link {
                url
              }
            }
            copyright {
              text
            }
            terms_and_conditions_name
            terms_and_conditions_page {
              url
            }
            anpc_name
            anpc_page {
              url
            }
            gdpr_name
            gdpr_page {
              url
            }
            cookie_policy_name
            cookie_policy_page {
              url
            }
            body {
              ... on PrismicFooterBodyNavigationGroups {
                primary {
                  group_name
                  group {
                    id
                    url
                  }
                }
                items {
                  link_name
                  link {
                    id
                    url
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Footer data={data.prismicFooter.data} {...props} />}
  />
)
