import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import style from '../../css/singleLineTiles.module.css'

export default class SingleLineTilesImageText extends Component {
  render() {
    const { data } = this.props

    return (
      <div className={`${style.container} ${style.whiteBackground} centerFlex`}>
        <div className={`${style.content} centerFlex maxContent`}>
          {data.items.map(item => (
            <a href={item.link.url}>
              <div className={style.link}>
                <img
                  className={style.subcategoryImg}
                  src={item.image.localFile.url}
                  alt={item.image.alt}
                  key={item.image.alt}
                />
                <h4>{item.title}</h4>
              </div>
            </a>
          ))}
        </div>
      </div>
    )
  }
}

SingleLineTilesImageText.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        link: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }),
        image: PropTypes.shape({
          localFile: PropTypes.shape({
            url: PropTypes.string.isRequired,
            childImageSharp: PropTypes.shape({
              fluid: PropTypes.any.isRequired,
            }),
          }),
          alt: PropTypes.any.isRequired,
        }),
      })
    ),
  }).isRequired,
}
