import React, { Component } from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import style from '../../css/callToAction.module.css'

export default class CallToActionSubscribe extends Component {
  render() {
    const { data } = this.props

    return (
      <div className={`${style.container} centerFlex`}>
        <Img
          className="background"
          fluid={data.dataSubscribe.background.localFile.childImageSharp.fluid}
          alt={data.dataSubscribe.background.alt}
        />
        <div className={style.content}>
          <h1>{data.dataSubscribe.title}</h1>
          <p>{data.dataSubscribe.description}</p>
          <form
            action="https://gazonul.us20.list-manage.com/subscribe/post?u=f84399f074954ce74f7760067&amp;id=f23afeb777"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            target="_blank"
            noValidate
          >
            <input
              className={style.inputfield}
              type="email"
              name="EMAIL"
              defaultValue=""
              required
              placeholder={data.dataSubscribe.button_placeholder}
            />
            <input className={style.submit} type="submit" value={data.dataSubscribe.button_text} />
          </form>
          <p className={style.gdprterms}>{data.dataSubscribe.gdpragreementtext.text}</p>
        </div>
      </div>
    )
  }
}

CallToActionSubscribe.propTypes = {
  data: PropTypes.shape({
    dataSubscribe: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      button_placeholder: PropTypes.string,
      button_text: PropTypes.string.isRequired,
      gdpragreementtext: PropTypes.shape({
        text: PropTypes.string.isRequired,
      }).isRequired,
      background: PropTypes.shape({
        localFile: PropTypes.shape({
          url: PropTypes.string.isRequired,
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.any.isRequired,
          }).isRequired,
        }),
        alt: PropTypes.any.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}
