import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from '../../css/timeline.module.css'

export default class Timeline extends Component {
  render() {
    const { data } = this.props

    return (
      <div className={`${style.container} centerFlex`}>
        {data.items.map(item => (
          <div className={style.timelineComponent} key={item.title}>
            <h4>{item.title}</h4>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    )
  }
}

Timeline.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
}
