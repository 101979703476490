import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import style from '../../css/iconTile.module.css'

export default class IconTile extends Component {
  render() {
    const { data } = this.props

    return (
      <div className={style.container} key={data.title}>
        {data.image.localFile.extension !== 'svg' ? (
          <img src={data.image.localFile.url} alt={data.image.alt} />
        ) : (
          <img src={data.image.localFile.url} alt={data.image.alt} />
        )}
        <h3>{data.title}</h3>
        <p>{data.description}</p>
      </div>
    )
  }
}

IconTile.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.shape({
      localFile: PropTypes.shape({
        extension: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        childImageSharp: PropTypes.shape({
          fluid: PropTypes.any.isRequired,
        }),
      }),
      alt: PropTypes.any.isRequired,
    }),
  }).isRequired,
}
