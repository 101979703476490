import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Video extends Component {
  render() {
    const { data } = this.props

    return (
      <div className="centerFlex">
        {/* <div className="embed centerFlex">
                  <iframe src={data.primary.link.url} />
              </div> */}
        {/* <video className="embed" controls>
          <source src={data.primary.link.url} type="video/mp4" />
        </video> */}
      </div>
    )
  }
}

Video.propTypes = {
  data: PropTypes.shape({
    primary: PropTypes.shape({
      link: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
}
