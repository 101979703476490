import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Accordion,
  ArticleTile,
  CallToActionSubscribe,
  CallToActionMultiButton,
  Image,
  CategoryTile,
  Product,
  ProductPreview,
  Recommendation,
  Slideshow,
  SlideshowMare,
  Text,
  TitleWithTextCentered,
  PageHeader,
  Tiles,
  Contact,
  Offer,
  Embed,
  ListGroup,
  ListTitleMultiTextImage,
  TableCharacteristics,
  Timeline,
  ImageMultiButton,
  RepeatableImageText,
  MultiTabImageText,
  SlideshowImageStatic,
  SingleLineTilesText,
  SlideshowTextStatic,
  SingleLineTilesIconsText,
  SingleLineTilesImageText,
  LogoParteneri,
  Video,
  Gallery,
  Youtube,
} from './slices'

export default class SliceZone extends Component {
  render() {
    const { allSlices, params } = this.props
    if (allSlices === null) {
      return null
    }
    const slice = allSlices.map(s => {
      switch (s.slice_type) {
        // These are the API IDs of the slices
        case 'Accordion':
          return <Accordion key={s.id} data={s} />
        case 'ArticleTile':
          return <ArticleTile key={s.id} data={s} />
        case 'CallToActionSubscribe':
          return <CallToActionSubscribe key={s.id} data={s} />
        case 'CallToActionMultiButton':
          return <CallToActionMultiButton key={s.id} data={s} />
        case 'Image':
          return <Image key={s.id} data={s} />
        case 'CategoryTile':
          return <CategoryTile key={s.id} data={s} />
        case 'Product':
          return <Product key={s.id} data={s} params={params} />
        case 'ProductPreview':
          return <ProductPreview key={s.id} data={s} />
        case 'Recommendation':
          return <Recommendation key={s.id} data={s} />
        case 'Slideshow':
          return <Slideshow key={s.id} data={s} />
        case 'slideshowmare':
          return <SlideshowMare key={s.id} data={s} />
        case 'Text':
          return <Text key={s.id} data={s} />
        case 'TitleWithTextCentered':
          return <TitleWithTextCentered key={s.id} data={s} />
        case 'PageHeader':
          return <PageHeader key={s.id} data={s} />
        case 'Tiles':
          return <Tiles key={s.id} data={s} />
        case 'Contact':
          return <Contact key={s.id} data={s} />
        case 'Offer':
          return <Offer key={s.id} data={s} />
        case 'Embed':
          return <Embed key={s.id} data={s} />
        case 'ListGroup':
          return <ListGroup key={s.id} data={s} />
        case 'ListTitleMultiTextImage':
          return <ListTitleMultiTextImage key={s.id} data={s} />
        case 'TableCharacteristics':
          return <TableCharacteristics key={s.id} data={s} />
        case 'Timeline':
          return <Timeline key={s.id} data={s} />
        case 'ImageMultiButton':
          return <ImageMultiButton key={s.id} data={s} />
        case 'RepeatableImageText':
          return <RepeatableImageText key={s.id} data={s} />
        case 'MultiTabImageText':
          return <MultiTabImageText key={s.id} data={s} />
        case 'SlideshowImageStatic':
          return <SlideshowImageStatic key={s.id} data={s} />
        case 'SingleLineTilesText':
          return <SingleLineTilesText key={s.id} data={s} />
        case 'SlideshowTextStatic':
          return <SlideshowTextStatic key={s.id} data={s} />
        case 'SingleLineTilesIconsText':
          return <SingleLineTilesIconsText key={s.id} data={s} />
        case 'SingleLineTilesImageText':
          return <SingleLineTilesImageText key={s.id} data={s} />
        case 'LogoParteneri':
          return <LogoParteneri key={s.id} data={s} />
        case 'Video':
          return <Video key={s.id} data={s} />
        case 'Gallery':
          return <Gallery key={s.id} data={s} />
        case 'Youtube':
          return <Youtube key={s.id} data={s} />
        default:
          return null
      }
    })
    return <main className="centerFlex">{slice}</main>
  }
}

SliceZone.propTypes = {
  allSlices: PropTypes.array.isRequired,
  params: PropTypes.any.isRequired,
}
