/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import NavMenu from './navMenu'
import MobileMenu from './mobileMenu'
import imgLogo from '../../static/logo_white.svg'
import style from '../css/header.module.css'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileMenuOpen: false,
    }
  }

  render() {
    const { data } = this.props
    const { mobileMenuOpen } = this.state

    return (
      <div className={style.container}>
        <header className={style.header}>
          <div className={style.logoMenu}>
            <div className={style.logo}>
              <a href="/">
                <img src={imgLogo} alt="logo-gazonul" className="logo" />
              </a>
            </div>
            <div
              className={style.menu}
              onClick={() => {
                this.setState({
                  mobileMenuOpen: !mobileMenuOpen,
                })
              }}
              onKeyDown={() => null}
            >
              <i className={`fa fa-2x${mobileMenuOpen ? ' fa-close' : ' fa-bars'}`} />
            </div>
          </div>
          <div className={style.navMenu}>
            <NavMenu data={data} />
          </div>
          <div className={style.phone}>
            {/* <i className="fa fa-2x fa-phone" />
            <a href="tel:+40371503212">
              <h2>0371 503 212</h2>
            </a> */}
          </div>
        </header>
        <div className={`${mobileMenuOpen ? style.mobileMenuOpen : style.mobileMenuClose}`}>
          <MobileMenu data={data} />
        </div>
      </div>
    )
  }
}

Header.propTypes = {
  data: PropTypes.object.isRequired,
}

// la components se foloseste StaticQuery :
// https://github.com/gatsbyjs/gatsby/issues/5102
// https://www.gatsbyjs.org/docs/static-query/
// eslint-disable-next-line react/display-name
export default props => (
  <StaticQuery
    query={graphql`
      query {
        prismicHeader {
          data {
            name
            mobile_menu_show_all_text
            navigation_menu {
              menu_item_name
              mobile_menu_show_all
              link {
                document {
                  id
                }
                url
              }
            }
            body {
              ... on PrismicHeaderBodyNavigationGroup {
                primary {
                  navigation_menu_item_name
                  navigation_group_name
                  mobile_menu_show_all
                  link {
                    document {
                      id
                    }
                    url
                  }
                }
                items {
                  menu_item_name
                  link {
                    document {
                      id
                    }
                    url
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Header data={data.prismicHeader.data} {...props} />}
  />
)
