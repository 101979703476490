import React, { Component } from 'react'
import PropTypes from 'prop-types'

import style from '../../css/text.module.css'

export default class Text extends Component {
  render() {
    const { data } = this.props

    return (
      <div
        id={`${data.primary.anchorid}`}
        className={`${style.link} contentText ${data.primary.type}`}
        dangerouslySetInnerHTML={{
          __html: `${data.primary.text.html} `,
        }}
      />
    )
  }
}

Text.propTypes = {
  data: PropTypes.shape({
    primary: PropTypes.shape({
      type: PropTypes.string.isRequired,
      anchorid: PropTypes.string,
      text: PropTypes.shape({
        html: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
}
