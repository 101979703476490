import React, { Component } from 'react'
import ReactResizeDetector from 'react-resize-detector'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup, Dot } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import style from '../../css/slideshowTextStatic.module.css'

const ICustom = styled.i`
  opacity: ${props => (props.slide === props.currentSlide ? 0.9 : 0.5)};
`

export default class SlideshowTextStatic extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeSlide: 0,
      time: Date.now(),
      naturalHeight: 1400,
    }
  }

  componentDidMount() {
    this.interval = setInterval(
      () =>
        this.setState((prevState, props) => ({
          time: Date.now(),
          activeSlide: props.data.items.length - 1 === prevState.activeSlide ? 0 : prevState.activeSlide + 1,
        })),
      5000
    )
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  onResize = width => {
    this.setState(() => ({ naturalHeight: width > 1024 ? 1400 : 2500 }))
  }

  render() {
    const { data } = this.props
    // eslint-disable-next-line no-unused-vars
    const { activeSlide, naturalHeight, time } = this.state
    return (
      <div className={style.container}>
        <ReactResizeDetector handleWidth onResize={this.onResize}>
          <CarouselProvider
            className={style.carousel}
            naturalSlideWidth={3000}
            naturalSlideHeight={naturalHeight}
            totalSlides={data.items.length}
            infinite
            touchEnabled
            dragEnabled
            dragStep={1}
            step={1}
            currentSlide={activeSlide}
          >
            <Slider>
              {data.items.map((item, index) => (
                <Slide className={style.slide} key={item.image.alt} index={index}>
                  <Img
                    className={style.image}
                    fluid={item.image.localFile.childImageSharp.fluid}
                    alt={item.image.alt}
                  />
                </Slide>
              ))}
            </Slider>
            <div className={style.overlay}>
              <div className={style.staticText}>
                <h1>{data.dataSlideshow.title}</h1>
                <p>{data.dataSlideshow.description}</p>
              </div>
              <ButtonBack className={`${style.button} ${style.back}`}>
                <i className={`fa fa-5x fa-arrow-circle-left ${style.arrows}`} />
              </ButtonBack>
              <ButtonNext className={`${style.button} ${style.next}`}>
                <i className={`fa fa-5x fa-arrow-circle-right ${style.arrows}`} />
              </ButtonNext>
              <DotGroup
                className={`${style.button} ${style.dotGroup}`}
                disableActiveDots={false}
                showAsSelectedForCurrentSlideOnly
                renderDots={() =>
                  data.items.map((item, index) => (
                    <Dot slide={index}>
                      <ICustom slide={index} currentSlide={activeSlide} className="fa fa-1x fa-circle" />
                    </Dot>
                  ))
                }
              />
            </div>
          </CarouselProvider>
        </ReactResizeDetector>
      </div>
    )
  }
}

SlideshowTextStatic.propTypes = {
  data: PropTypes.shape({
    dataSlideshow: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
    items: PropTypes.arrayOf({
      image: PropTypes.shape({
        localFile: PropTypes.shape({
          url: PropTypes.string.isRequired,
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
              originalName: PropTypes.string.isRequired,
            }),
          }),
        }),
        alt: PropTypes.any.isRequired,
      }),
    }),
  }).isRequired,
}
