import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from '../../css/tableCharacteristics.module.css'

export default class TableCharacteristics extends Component {
  render() {
    const { data } = this.props

    return (
      <div className={`${style.container} maxContent centerFlex`}>
        <h3 className="underline-red underline-center">{data.primary.title.text}</h3>
        <table className={style.content}>
          <tbody>
            {data.items.map(item => (
              <tr key={item.characteristic.text}>
                <td>{item.characteristic.text}</td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: `${item.value.html} `,
                  }}
                />
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

TableCharacteristics.propTypes = {
  data: PropTypes.shape({
    primary: PropTypes.shape({
      title: PropTypes.shape({
        text: PropTypes.string.isRequired,
      }),
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        characteristic: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
        value: PropTypes.shape({
          html: PropTypes.string.isRequired,
        }),
      })
    ),
  }).isRequired,
}
