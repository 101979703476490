import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import style from '../../css/multiTab.module.css'

export default class MultiTabImageText extends Component {
  render() {
    const { data } = this.props

    return (
      <Tabs className={`${style.container}`}>
        <TabList className={`${style.tabList}`}>
          {data.items.map(tab => (
            <Tab selectedClassName={style.selectedTab} className={style.tabTitle} key={tab.title}>
              <div>
                <h4>{tab.title}</h4>
              </div>
            </Tab>
          ))}
        </TabList>

        {data.items.map(tab => (
          <TabPanel className={style.content} key={tab.title}>
            <div
              className={style.tabText}
              dangerouslySetInnerHTML={{
                __html: `${tab.text.html} `,
              }}
            />
            <img className={style.tabImg} src={tab.image.localFile.url} alt={tab.image.alt} />
          </TabPanel>
        ))}
      </Tabs>
    )
  }
}

MultiTabImageText.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        text: PropTypes.shape({
          html: PropTypes.string.isRequired,
        }),
        image: PropTypes.shape({
          localFile: PropTypes.shape({
            url: PropTypes.string.isRequired,
            childImageSharp: PropTypes.shape({
              fluid: PropTypes.any.isRequired,
            }),
          }),
          alt: PropTypes.any.isRequired,
        }),
      }).isRequired
    ).isRequired,
  }).isRequired,
}
