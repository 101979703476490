import React, { Component } from 'react'
import CheckboxGroup from 'react-checkbox-group'
import PropTypes from 'prop-types'
import Carousel from 'nuka-carousel'
import ReactResizeDetector from 'react-resize-detector'
import style from '../../css/product.module.css'
import imgReseller from '../../../static/reseller.png'

export default class Product extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeVariation: 0,
      withoutControls: false,
    }
  }

  componentDidMount() {
    const { params } = this.props
    const { activeVariation } = this.state

    if ((params === 0 || params === 1) && params !== activeVariation) this.setState({ activeVariation: params })
  }

  render() {
    const { data } = this.props
    const { activeVariation, withoutControls } = this.state
    const { product } = data.primary.product.document[0]
    const productVariations = product.variations.map(elem => elem.primary.type)

    const variations = []
    for (let index = 0; index < productVariations.length; index += 1) {
      variations[productVariations[index]] = product.variations[index].items
    }

    return (
      <div className={style.container}>
        <div className={style.content}>
          <ReactResizeDetector
            handleWidth
            onResize={width => {
              this.setState(() => ({ withoutControls: width < 300 }))
            }}
          />
          <Carousel
            className={style.carousel}
            cellAlign="right"
            slidesToShow={1}
            withoutControls={withoutControls}
            wrapAround
            heightMode="current"
            renderCenterLeftControls={({ previousSlide }) => (
              <button className={style.button} onClick={previousSlide} type="button">
                <i className={`fa fa-3x fa-arrow-left ${style.arrows}`} />
              </button>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <button className={style.button} onClick={nextSlide} type="button">
                <i className={`fa fa-3x fa-arrow-right ${style.arrows}`} />
              </button>
            )}
          >
            {variations[productVariations[activeVariation]].map(item => (
              <img
                className={style.image}
                key={item.images.alt}
                src={item.images.localFile.url}
                alt={item.images.alt}
              />
            ))}
          </Carousel>
        </div>
        <div className={style.rightside}>
          <h1>{product.name}</h1>
          <p>{product.description.text}</p>
          {product.variations.length === 1 ? null : (
            <div>
              <h3>Mărime</h3>
              <CheckboxGroup
                name="variations"
                value={[productVariations[activeVariation]]}
                onChange={() => this.setState({ activeVariation: activeVariation === 0 ? 1 : 0 })}
              >
                {Checkbox => (
                  <>
                    {product.variations.map(variation => (
                      <div className={style.checkboxContainer}>
                        <Checkbox className={style.checkbox} value={variation.primary.type} />
                        {variation.primary.type}
                      </div>
                    ))}
                  </>
                )}
              </CheckboxGroup>
              {product.name.includes('Semințe') || product.name.includes('Fertilizant') ? (
                <div>
                  <h3>Disponibil</h3>
                  <img src={imgReseller} alt="reseller" />
                </div>
              ) : (
                <div />
              )}
            </div>
          )}
          {/* <Button data="Buy Now" /> */}
        </div>
      </div>
    )
  }
}

Product.propTypes = {
  data: PropTypes.shape({
    primary: PropTypes.shape({
      product: PropTypes.shape({
        document: PropTypes.shape({
          id: PropTypes.string.isRequired,
          product: PropTypes.shape({
            name: PropTypes.string.isRequired,
            description: PropTypes.shape({
              text: PropTypes.string.isRequired,
            }),
            variations: PropTypes.arrayOf(
              PropTypes.shape({
                primary: PropTypes.shape({
                  type: PropTypes.string.isRequired,
                }),
                items: PropTypes.arrayOf(
                  PropTypes.shape({
                    localFile: PropTypes.shape({
                      url: PropTypes.string.isRequired,
                      childImageSharp: PropTypes.shape({
                        fluid: PropTypes.shape({
                          originalName: PropTypes.string.isRequired,
                        }),
                      }),
                    }),
                    alt: PropTypes.any.isRequired,
                  }).isRequired
                ),
              })
            ),
          }),
        }),
      }),
    }),
    items: PropTypes.array.isRequired,
  }).isRequired,
  params: PropTypes.any.isRequired,
}
