import React, { Component } from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import style from '../../css/listTitleMultiTextImage.module.css'

export default class ListTitleMultiTextImage extends Component {
  render() {
    const { data } = this.props
    const hasTitle = data.primary.title.text === undefined || data.primary.title.text === null

    return (
      <div className={`${style.container} maxContent centerFlex`}>
        {hasTitle ? <h3 className={style.verticalText}>{data.primary.title.text}</h3> : ''}
        <div className={`${style.content} centerFlex ${hasTitle ? style.shadow : ''}`}>
          {data.listItems.map(item => (
            <div key={item.description.text}>
              <img className={style.listImg} src={item.image.localFile.url} alt={item.image.alt} />
              <h4>{item.description.text}</h4>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

ListTitleMultiTextImage.propTypes = {
  data: PropTypes.shape({
    primary: PropTypes.shape({
      title: PropTypes.shape({
        text: PropTypes.string,
      }),
    }).isRequired,
    listItems: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
        image: PropTypes.shape({
          localFile: PropTypes.shape({
            url: PropTypes.string.isRequired,
            childImageSharp: PropTypes.shape({
              fluid: PropTypes.any.isRequired,
            }),
          }),
          alt: PropTypes.any.isRequired,
        }),
      })
    ).isRequired,
  }).isRequired,
}
