import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from '../../css/contact.module.css'

export default class Contact extends Component {
  render() {
    const { data } = this.props

    return (
      <div className={style.container}>
        <div
          className={style.content}
          dangerouslySetInnerHTML={{
            __html: `${data.primary.text.html} `,
          }}
        />
        <iframe className={style.map} title="Gazonul" src={data.primary.link.url} frameBorder="0" allowFullScreen />
      </div>
    )
  }
}

Contact.propTypes = {
  data: PropTypes.shape({
    primary: PropTypes.shape({
      text: PropTypes.shape({
        html: PropTypes.string.isRequired,
      }).isRequired,
      link: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}
