import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import styled from 'styled-components'
import style from '../../css/embed.module.css'

// const ICustom = styled.i`
//   opacity: ${props => (props.slide === props.currentSlide ? 0.9 : 0.5)};
// `

export default class Embed extends Component {
  render() {
    const { data } = this.props
    return (
      <div className={style.mainContainer}>
        <div className={style.container}>
          <video
            id="vid"
            className={style.embed}
            autoPlay
            loop
            muted
            // onPause={() => {
            //   const vid = document.getElementById('vid')
            //   vid.currentTime = 0
            //   vid.play()
            // }}
          >
            <source src={`${data.primary.video.url}`} type="video/mp4" />
            Browser-ul dvs. nu suporta elementul html de tip video.
          </video>
        </div>
        <div className={style.header}>
          <h1>{data.primary.title_header}</h1>
          <p>{data.primary.description_header}</p>
        </div>
      </div>
    )
  }
}

Embed.propTypes = {
  data: PropTypes.shape({
    primary: PropTypes.shape({
      title_header: PropTypes.string.isRequired,
      description_header: PropTypes.string.isRequired,
      video: PropTypes.shape({
        url: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
}
